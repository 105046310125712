@import '~antd/dist/antd.variable.less';

#root {
  width: 100%;
  height: 100%;
}

:root {
  // 主题色
  --ying-primary-color: #5D93FF;
  --ant-primary-color: #5D93FF;
  // 内页主标题色
  --ying-page-header-color: #3c6cfb;
  --ying-page-title-color: #1335A7;
  // 内页位置分割线颜色
  --ying-page-location-line-color: #5D93FF;
  // 一般边框颜色
  --ying-border-color: #d9d9d9;
  // 筛选条件边框色
  --ying-page-condition-border-color: #CFD2D6;
  // 浅色分割边框色
  --ying-light-border-color: #B4BCC3;
  // 普通文字颜色
  --ying-txt-color: #3D434B;
  // 描述文字颜色
  --ying-txt-desc-color: #1E2228;
  // 描述文字颜色1
  --ying-txt-desc1-color: #848F9C;
  // 特殊定制颜色
  --ying-spec-color: #5D93FF;
  // 菜单选中主菜单颜色
  --ying-menu-main-color: #1335A7;
  // 菜单背景色
  --ying-menu-bg-color: #ffffff;
  // 菜单文字颜色
  --ying-menu-txt-color: #1E2228;
  // 特殊背景色
  --ying-spec-bg-color: #EFEFEF;
  // 其他背景色
  --ying-normal-bg-color: #FFFAF1;
  // 其他背景色
  --ying-use-less-bg-color: #FAFAFA;
  // 渐变背景1
  --ying-linear-gradient-1: #1335A7;
  // 渐变背景2
  --ying-linear-gradient-2: #5D93FF;
}

.icon-c1,.icon-c2,.icon-c3,.icon-c4,.icon-c5,.icon-c6,.icon-c7,.icon-c8,.icon-ni1,.icon-ni1,.icon-ni2,.icon-ni3,.icon-ni4,.icon-ni5,.icon-ni6,.icon-ni7,.icon-ni8,.icon-ni9,.icon-ni10,.icon-ni11,.icon-ni12,.icon-ni13,.icon-ni14,.icon-ni15,.icon-ni16,.icon-ni17,.icon-ni18,.icon-ni19,.icon-ni20,.icon-ni21,.icon-ni22,.icon-ni23,.icon-ni24,.icon-ni25,.icon-ni26,.icon-ni27,.icon-ni28,.icon-ni29,.icon-ni30,.icon-ni31,.icon-ni32,.icon-ni33,.icon-ni34,.icon-ni35,.icon-ni36,.icon-ni37,.icon-ni38,.icon-ni39,.icon-ni40,.icon-ni41,.icon-ni42,.icon-ni43,.icon-ni44,.icon-ni45,.icon-ni46,.icon-ni47,.icon-ni48,.icon-ni49,.icon-ni50,.icon-ni51,.icon-ni52,.icon-ni53,.icon-ni54,.icon-ni55,.icon-ni56,.icon-ni57,.icon-ni58,.icon-ni59,.icon-ni60,.icon-ni61,.icon-my-home,.icon-my-intro,.icon-my-photo,.icon-my-space,.icon-my-card,.icon-r1,.icon-r2,.icon-r3,.icon-r4,.icon-r5,.icon-r6,.icon-r7,.icon-r8,.icon-r9,.icon-r10,.icon-r11,.icon-r12,.icon-r13,.icon-r14,.icon-r15,.icon-r16,.icon-r17,.icon-r18,.icon-r19,.icon-r20,.icon-r21,.icon-g1,.icon-g2,.icon-g3,.icon-g4,.icon-g5,.icon-g6,.icon-g7,.icon-g8,.icon-g9,.icon-g10,.icon-g11,.icon-g12,.icon-g13,.icon-g14,.icon-g15,.icon-g16,.icon-g17,.icon-g18,.icon-g19,.icon-g20,.icon-g21,.icon-b1,.icon-b2,.icon-b3,.icon-b4,.icon-b5,.icon-b6,.icon-b7,.icon-b8,.icon-b9,.icon-b10,.icon-b11,.icon-b12,.icon-b13,.icon-b14,.icon-b15,.icon-b16,.icon-b17,.icon-b18,.icon-b19,.icon-b20,.icon-b21,.icon-y1,.icon-y2,.icon-y3,.icon-y4,.icon-y5,.icon-y6,.icon-y7,.icon-y8,.icon-y9,.icon-y10,.icon-y11,.icon-y12,.icon-y13,.icon-y14,.icon-y15,.icon-y16,.icon-y17,.icon-y18,.icon-y19,.icon-y20,.icon-y21{width: 48px;height:48px; display: block;border-radius: 24px;}
.icon-c1{ background:url('https://cdn.yingzhiying.com/img/c1.png') !important;background-size:100% 100% !important;}
.icon-c2{ background:url('https://cdn.yingzhiying.com/img/c2.png') !important;background-size:100% 100% !important;}
.icon-c3{ background:url('https://cdn.yingzhiying.com/img/c3.png') !important;background-size:100% 100% !important;}
.icon-c4{ background:url('https://cdn.yingzhiying.com/img/c4.png') !important;background-size:100% 100% !important;}
.icon-c5{ background:url('https://cdn.yingzhiying.com/img/c5.png') !important;background-size:100% 100% !important;}
.icon-c6{ background:url('https://cdn.yingzhiying.com/img/c6.png') !important;background-size:100% 100% !important;}
.icon-c7{ background:url('https://cdn.yingzhiying.com/img/c7.png') !important;background-size:100% 100% !important;}
.icon-c8{ background:url('https://cdn.yingzhiying.com/img/c8.png') !important;background-size:100% 100% !important;}

.icon-ni1{background:url('https://cdn.yingzhiying.com/img/ni1.svg'); background-size:100% 100%;}
.icon-ni2{background:url('https://cdn.yingzhiying.com/img/ni2.svg'); background-size:100% 100%;}
.icon-ni3{background:url('https://cdn.yingzhiying.com/img/ni3.svg'); background-size:100% 100%;}
.icon-ni4{background:url('https://cdn.yingzhiying.com/img/ni4.svg'); background-size:100% 100%;}
.icon-ni5{background:url('https://cdn.yingzhiying.com/img/ni5.svg'); background-size:100% 100%;}
.icon-ni6{background:url('https://cdn.yingzhiying.com/img/ni6.svg'); background-size:100% 100%;}
.icon-ni7{background:url('https://cdn.yingzhiying.com/img/ni7.svg'); background-size:100% 100%;}
.icon-ni8{background:url('https://cdn.yingzhiying.com/img/ni8.svg'); background-size:100% 100%;}
.icon-ni9{background:url('https://cdn.yingzhiying.com/img/ni9.svg'); background-size:100% 100%;}
.icon-ni10{background:url('https://cdn.yingzhiying.com/img/ni10.svg'); background-size:100% 100%;}
.icon-ni11{background:url('https://cdn.yingzhiying.com/img/ni11.svg'); background-size:100% 100%;}
.icon-ni12{background:url('https://cdn.yingzhiying.com/img/ni12.svg'); background-size:100% 100%;}
.icon-ni13{background:url('https://cdn.yingzhiying.com/img/ni13.svg'); background-size:100% 100%;}
.icon-ni14{background:url('https://cdn.yingzhiying.com/img/ni14.svg'); background-size:100% 100%;}
.icon-ni15{background:url('https://cdn.yingzhiying.com/img/ni15.svg'); background-size:100% 100%;}
.icon-ni16{background:url('https://cdn.yingzhiying.com/img/ni16.svg'); background-size:100% 100%;}
.icon-ni17{background:url('https://cdn.yingzhiying.com/img/ni17.svg'); background-size:100% 100%;}
.icon-ni18{background:url('https://cdn.yingzhiying.com/img/ni18.svg'); background-size:100% 100%;}
.icon-ni19{background:url('https://cdn.yingzhiying.com/img/ni19.svg'); background-size:100% 100%;}
.icon-ni20{background:url('https://cdn.yingzhiying.com/img/ni20.svg'); background-size:100% 100%;}
.icon-ni21{background:url('https://cdn.yingzhiying.com/img/ni21.svg'); background-size:100% 100%;}
.icon-ni22{background:url('https://cdn.yingzhiying.com/img/ni22.svg'); background-size:100% 100%;}
.icon-ni23{background:url('https://cdn.yingzhiying.com/img/ni23.svg'); background-size:100% 100%;}
.icon-ni24{background:url('https://cdn.yingzhiying.com/img/ni24.svg'); background-size:100% 100%;}
.icon-ni25{background:url('https://cdn.yingzhiying.com/img/ni25.svg'); background-size:100% 100%;}
.icon-ni26{background:url('https://cdn.yingzhiying.com/img/ni26.svg'); background-size:100% 100%;}
.icon-ni27{background:url('https://cdn.yingzhiying.com/img/ni27.svg'); background-size:100% 100%;}
.icon-ni28{background:url('https://cdn.yingzhiying.com/img/ni28.svg'); background-size:100% 100%;}
.icon-ni29{background:url('https://cdn.yingzhiying.com/img/ni29.svg'); background-size:100% 100%;}
.icon-ni30{background:url('https://cdn.yingzhiying.com/img/ni30.svg'); background-size:100% 100%;}
.icon-ni31{background:url('https://cdn.yingzhiying.com/img/ni31.svg'); background-size:100% 100%;}
.icon-ni32{background:url('https://cdn.yingzhiying.com/img/ni32.svg'); background-size:100% 100%;}
.icon-ni33{background:url('https://cdn.yingzhiying.com/img/ni33.svg'); background-size:100% 100%;}
.icon-ni34{background:url('https://cdn.yingzhiying.com/img/ni34.svg'); background-size:100% 100%;}
.icon-ni35{background:url('https://cdn.yingzhiying.com/img/ni35.svg'); background-size:100% 100%;}
.icon-ni36{background:url('https://cdn.yingzhiying.com/img/ni36.svg'); background-size:100% 100%;}
.icon-ni37{background:url('https://cdn.yingzhiying.com/img/ni37.svg'); background-size:100% 100%;}
.icon-ni38{background:url('https://cdn.yingzhiying.com/img/ni38.svg'); background-size:100% 100%;}
.icon-ni39{background:url('https://cdn.yingzhiying.com/img/ni39.svg'); background-size:100% 100%;}
.icon-ni40{background:url('https://cdn.yingzhiying.com/img/ni40.svg'); background-size:100% 100%;}
.icon-ni41{background:url('https://cdn.yingzhiying.com/img/ni41.svg'); background-size:100% 100%;}
.icon-ni42{background:url('https://cdn.yingzhiying.com/img/ni42.svg'); background-size:100% 100%;}
.icon-ni43{background:url('https://cdn.yingzhiying.com/img/ni43.svg'); background-size:100% 100%;}
.icon-ni44{background:url('https://cdn.yingzhiying.com/img/ni44.svg'); background-size:100% 100%;}
.icon-ni45{background:url('https://cdn.yingzhiying.com/img/ni45.svg'); background-size:100% 100%;}
.icon-ni46{background:url('https://cdn.yingzhiying.com/img/ni46.svg'); background-size:100% 100%;}
.icon-ni47{background:url('https://cdn.yingzhiying.com/img/ni47.svg'); background-size:100% 100%;}
.icon-ni48{background:url('https://cdn.yingzhiying.com/img/ni48.svg'); background-size:100% 100%;}
.icon-ni49{background:url('https://cdn.yingzhiying.com/img/ni49.svg'); background-size:100% 100%;}
.icon-ni50{background:url('https://cdn.yingzhiying.com/img/ni50.svg'); background-size:100% 100%;}
.icon-ni51{background:url('https://cdn.yingzhiying.com/img/ni51.svg'); background-size:100% 100%;}
.icon-ni52{background:url('https://cdn.yingzhiying.com/img/ni52.svg'); background-size:100% 100%;}
.icon-ni53{background:url('https://cdn.yingzhiying.com/img/ni53.svg'); background-size:100% 100%;}
.icon-ni54{background:url('https://cdn.yingzhiying.com/img/ni54.svg'); background-size:100% 100%;}
.icon-ni55{background:url('https://cdn.yingzhiying.com/img/ni55.svg'); background-size:100% 100%;}
.icon-ni56{background:url('https://cdn.yingzhiying.com/img/ni56.svg'); background-size:100% 100%;}
.icon-ni57{background:url('https://cdn.yingzhiying.com/img/ni57.svg'); background-size:100% 100%;}
.icon-ni58{background:url('https://cdn.yingzhiying.com/img/ni58.svg'); background-size:100% 100%;}
.icon-ni59{background:url('https://cdn.yingzhiying.com/img/ni59.svg'); background-size:100% 100%;}
.icon-ni60{background:url('https://cdn.yingzhiying.com/img/ni60.svg'); background-size:100% 100%;}
.icon-ni61{background:url('https://cdn.yingzhiying.com/img/ni61.svg'); background-size:100% 100%;}
.icon-my-home{background:url('https://cdn.yingzhiying.com/img/my_home.svg'); background-size:100% 100% ;}
.icon-my-intro{background:url('https://cdn.yingzhiying.com/img/my_intro.svg'); background-size:100% 100% ;}
.icon-my-photo{background:url('https://cdn.yingzhiying.com/img/my_photo.svg'); background-size:100% 100% ;}
.icon-my-space{background:url('https://cdn.yingzhiying.com/img/my_space.svg'); background-size:100% 100% ;}
.icon-my-card{background:url('https://cdn.yingzhiying.com/img/my_card.svg'); background-size:100% 100% ;}
.icon-r1{background:url('https://cdn.yingzhiying.com/img/r1.svg'); background-size:100% 100%;}
.icon-r2{background:url('https://cdn.yingzhiying.com/img/r2.svg'); background-size:100% 100%;}
.icon-r3{background:url('https://cdn.yingzhiying.com/img/r3.svg'); background-size:100% 100%;}
.icon-r4{background:url('https://cdn.yingzhiying.com/img/r4.svg'); background-size:100% 100%;}
.icon-r5{background:url('https://cdn.yingzhiying.com/img/r5.svg'); background-size:100% 100%;}
.icon-r6{background:url('https://cdn.yingzhiying.com/img/r6.svg'); background-size:100% 100%;}
.icon-r7{background:url('https://cdn.yingzhiying.com/img/r7.svg'); background-size:100% 100%;}
.icon-r8{background:url('https://cdn.yingzhiying.com/img/r8.svg'); background-size:100% 100%;}
.icon-r9{background:url('https://cdn.yingzhiying.com/img/r9.svg'); background-size:100% 100%;}
.icon-r10{background:url('https://cdn.yingzhiying.com/img/r10.svg'); background-size:100% 100%;}
.icon-r11{background:url('https://cdn.yingzhiying.com/img/r11.svg'); background-size:100% 100%;}
.icon-r12{background:url('https://cdn.yingzhiying.com/img/r12.svg'); background-size:100% 100%;}
.icon-r13{background:url('https://cdn.yingzhiying.com/img/r13.svg'); background-size:100% 100%;}
.icon-r14{background:url('https://cdn.yingzhiying.com/img/r14.svg'); background-size:100% 100%;}
.icon-r15{background:url('https://cdn.yingzhiying.com/img/r15.svg'); background-size:100% 100%;}
.icon-r16{background:url('https://cdn.yingzhiying.com/img/r16.svg'); background-size:100% 100%;}
.icon-r17{background:url('https://cdn.yingzhiying.com/img/r17.svg'); background-size:100% 100%;}
.icon-r18{background:url('https://cdn.yingzhiying.com/img/r18.svg'); background-size:100% 100%;}
.icon-r19{background:url('https://cdn.yingzhiying.com/img/r19.svg'); background-size:100% 100%;}
.icon-r20{background:url('https://cdn.yingzhiying.com/img/r20.svg'); background-size:100% 100%;}
.icon-r21{background:url('https://cdn.yingzhiying.com/img/r21.svg'); background-size:100% 100%;}
.icon-g1{background:url('https://cdn.yingzhiying.com/img/g1.svg'); background-size:100% 100%;}
.icon-g2{background:url('https://cdn.yingzhiying.com/img/g2.svg'); background-size:100% 100%;}
.icon-g3{background:url('https://cdn.yingzhiying.com/img/g3.svg'); background-size:100% 100%;}
.icon-g4{background:url('https://cdn.yingzhiying.com/img/g4.svg'); background-size:100% 100%;}
.icon-g5{background:url('https://cdn.yingzhiying.com/img/g5.svg'); background-size:100% 100%;}
.icon-g6{background:url('https://cdn.yingzhiying.com/img/g6.svg'); background-size:100% 100%;}
.icon-g7{background:url('https://cdn.yingzhiying.com/img/g7.svg'); background-size:100% 100%;}
.icon-g8{background:url('https://cdn.yingzhiying.com/img/g8.svg'); background-size:100% 100%;}
.icon-g9{background:url('https://cdn.yingzhiying.com/img/g9.svg'); background-size:100% 100%;}
.icon-g10{background:url('https://cdn.yingzhiying.com/img/g10.svg'); background-size:100% 100%;}
.icon-g11{background:url('https://cdn.yingzhiying.com/img/g11.svg'); background-size:100% 100%;}
.icon-g12{background:url('https://cdn.yingzhiying.com/img/g12.svg'); background-size:100% 100%;}
.icon-g13{background:url('https://cdn.yingzhiying.com/img/g13.svg'); background-size:100% 100%;}
.icon-g14{background:url('https://cdn.yingzhiying.com/img/g14.svg'); background-size:100% 100%;}
.icon-g15{background:url('https://cdn.yingzhiying.com/img/g15.svg'); background-size:100% 100%;}
.icon-g16{background:url('https://cdn.yingzhiying.com/img/g16.svg'); background-size:100% 100%;}
.icon-g17{background:url('https://cdn.yingzhiying.com/img/g17.svg'); background-size:100% 100%;}
.icon-g18{background:url('https://cdn.yingzhiying.com/img/g18.svg'); background-size:100% 100%;}
.icon-g19{background:url('https://cdn.yingzhiying.com/img/g19.svg'); background-size:100% 100%;}
.icon-g20{background:url('https://cdn.yingzhiying.com/img/g20.svg'); background-size:100% 100%;}
.icon-g21{background:url('https://cdn.yingzhiying.com/img/g21.svg'); background-size:100% 100%;}
.icon-b1{background:url('https://cdn.yingzhiying.com/img/b1.svg'); background-size:100% 100%;}
.icon-b2{background:url('https://cdn.yingzhiying.com/img/b2.svg'); background-size:100% 100%;}
.icon-b3{background:url('https://cdn.yingzhiying.com/img/b3.svg'); background-size:100% 100%;}
.icon-b4{background:url('https://cdn.yingzhiying.com/img/b4.svg'); background-size:100% 100%;}
.icon-b5{background:url('https://cdn.yingzhiying.com/img/b5.svg'); background-size:100% 100%;}
.icon-b6{background:url('https://cdn.yingzhiying.com/img/b6.svg'); background-size:100% 100%;}
.icon-b7{background:url('https://cdn.yingzhiying.com/img/b7.svg'); background-size:100% 100%;}
.icon-b8{background:url('https://cdn.yingzhiying.com/img/b8.svg'); background-size:100% 100%;}
.icon-b9{background:url('https://cdn.yingzhiying.com/img/b9.svg'); background-size:100% 100%;}
.icon-b10{background:url('https://cdn.yingzhiying.com/img/b10.svg'); background-size:100% 100%;}
.icon-b11{background:url('https://cdn.yingzhiying.com/img/b11.svg'); background-size:100% 100%;}
.icon-b12{background:url('https://cdn.yingzhiying.com/img/b12.svg'); background-size:100% 100%;}
.icon-b13{background:url('https://cdn.yingzhiying.com/img/b13.svg'); background-size:100% 100%;}
.icon-b14{background:url('https://cdn.yingzhiying.com/img/b14.svg'); background-size:100% 100%;}
.icon-b15{background:url('https://cdn.yingzhiying.com/img/b15.svg'); background-size:100% 100%;}
.icon-b16{background:url('https://cdn.yingzhiying.com/img/b16.svg'); background-size:100% 100%;}
.icon-b17{background:url('https://cdn.yingzhiying.com/img/b17.svg'); background-size:100% 100%;}
.icon-b18{background:url('https://cdn.yingzhiying.com/img/b18.svg'); background-size:100% 100%;}
.icon-b19{background:url('https://cdn.yingzhiying.com/img/b19.svg'); background-size:100% 100%;}
.icon-b20{background:url('https://cdn.yingzhiying.com/img/b20.svg'); background-size:100% 100%;}
.icon-b21{background:url('https://cdn.yingzhiying.com/img/b21.svg'); background-size:100% 100%;}
.icon-y1{background:url('https://cdn.yingzhiying.com/img/y1.svg'); background-size:100% 100%;}
.icon-y2{background:url('https://cdn.yingzhiying.com/img/y2.svg'); background-size:100% 100%;}
.icon-y3{background:url('https://cdn.yingzhiying.com/img/y3.svg'); background-size:100% 100%;}
.icon-y4{background:url('https://cdn.yingzhiying.com/img/y4.svg'); background-size:100% 100%;}
.icon-y5{background:url('https://cdn.yingzhiying.com/img/y5.svg'); background-size:100% 100%;}
.icon-y6{background:url('https://cdn.yingzhiying.com/img/y6.svg'); background-size:100% 100%;}
.icon-y7{background:url('https://cdn.yingzhiying.com/img/y7.svg'); background-size:100% 100%;}
.icon-y8{background:url('https://cdn.yingzhiying.com/img/y8.svg'); background-size:100% 100%;}
.icon-y9{background:url('https://cdn.yingzhiying.com/img/y9.svg'); background-size:100% 100%;}
.icon-y10{background:url('https://cdn.yingzhiying.com/img/y10.svg'); background-size:100% 100%;}
.icon-y11{background:url('https://cdn.yingzhiying.com/img/y11.svg'); background-size:100% 100%;}
.icon-y12{background:url('https://cdn.yingzhiying.com/img/y12.svg'); background-size:100% 100%;}
.icon-y13{background:url('https://cdn.yingzhiying.com/img/y13.svg'); background-size:100% 100%;}
.icon-y14{background:url('https://cdn.yingzhiying.com/img/y14.svg'); background-size:100% 100%;}
.icon-y15{background:url('https://cdn.yingzhiying.com/img/y15.svg'); background-size:100% 100%;}
.icon-y16{background:url('https://cdn.yingzhiying.com/img/y16.svg'); background-size:100% 100%;}
.icon-y17{background:url('https://cdn.yingzhiying.com/img/y17.svg'); background-size:100% 100%;}
.icon-y18{background:url('https://cdn.yingzhiying.com/img/y18.svg'); background-size:100% 100%;}
.icon-y19{background:url('https://cdn.yingzhiying.com/img/y19.svg'); background-size:100% 100%;}
.icon-y20{background:url('https://cdn.yingzhiying.com/img/y20.svg'); background-size:100% 100%;}
.icon-y21{background:url('https://cdn.yingzhiying.com/img/y21.svg'); background-size:100% 100%;}

.channel-icon {
  width: 48px;
  height: 48px;
}

.icon-channel-100001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100001.png');background-size:100% 100%; }
.icon-channel-100002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100002.png');background-size:100% 100%; }
.icon-channel-100003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100003.png');background-size:100% 100%; }
.icon-channel-100004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100004.png');background-size:100% 100%; }
.icon-channel-100005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100005.png');background-size:100% 100%; }
.icon-channel-100006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100006.png');background-size:100% 100%; }
.icon-channel-100007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100007.png');background-size:100% 100%; }
.icon-channel-100008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100008.png');background-size:100% 100%; }
.icon-channel-100009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100009.png');background-size:100% 100%; }
.icon-channel-100010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100010.png');background-size:100% 100%; }
.icon-channel-100011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100011.png');background-size:100% 100%; }
.icon-channel-100012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100012.png');background-size:100% 100%; }
.icon-channel-100013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100013.png');background-size:100% 100%; }
.icon-channel-100014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100014.png');background-size:100% 100%; }
.icon-channel-100015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100015.png');background-size:100% 100%; }
.icon-channel-100016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100016.png');background-size:100% 100%; }
.icon-channel-100017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100017.png');background-size:100% 100%; }
.icon-channel-100018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100018.png');background-size:100% 100%; }
.icon-channel-100019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100019.png');background-size:100% 100%; }
.icon-channel-100020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100020.png');background-size:100% 100%; }
.icon-channel-100021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100021.png');background-size:100% 100%; }
.icon-channel-100022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100022.png');background-size:100% 100%; }
.icon-channel-100023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100023.png');background-size:100% 100%; }
.icon-channel-100024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100024.png');background-size:100% 100%; }
.icon-channel-100025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100025.png');background-size:100% 100%; }
.icon-channel-100026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100026.png');background-size:100% 100%; }
.icon-channel-100027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100027.png');background-size:100% 100%; }
.icon-channel-100028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100028.png');background-size:100% 100%; }
.icon-channel-100029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100029.png');background-size:100% 100%; }
.icon-channel-100030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100030.png');background-size:100% 100%; }
.icon-channel-100031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100031.png');background-size:100% 100%; }
.icon-channel-100032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100032.png');background-size:100% 100%; }
.icon-channel-100033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100033.png');background-size:100% 100%; }
.icon-channel-100034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100034.png');background-size:100% 100%; }
.icon-channel-100035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100035.png');background-size:100% 100%; }
.icon-channel-100036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100036.png');background-size:100% 100%; }
.icon-channel-100037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100037.png');background-size:100% 100%; }
.icon-channel-100038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100038.png');background-size:100% 100%; }
.icon-channel-100039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100039.png');background-size:100% 100%; }
.icon-channel-100040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100040.png');background-size:100% 100%; }
.icon-channel-100041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100041.png');background-size:100% 100%; }
.icon-channel-100042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100042.png');background-size:100% 100%; }
.icon-channel-100043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100043.png');background-size:100% 100%; }
.icon-channel-100044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100044.png');background-size:100% 100%; }
.icon-channel-100045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100045.png');background-size:100% 100%; }
.icon-channel-100046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100046.png');background-size:100% 100%; }
.icon-channel-100047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100047.png');background-size:100% 100%; }
.icon-channel-100048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/100048.png');background-size:100% 100%; }
.icon-channel-200001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200001.png');background-size:100% 100%; }
.icon-channel-200002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200002.png');background-size:100% 100%; }
.icon-channel-200003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200003.png');background-size:100% 100%; }
.icon-channel-200004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200004.png');background-size:100% 100%; }
.icon-channel-200005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200005.png');background-size:100% 100%; }
.icon-channel-200006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200006.png');background-size:100% 100%; }
.icon-channel-200007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200007.png');background-size:100% 100%; }
.icon-channel-200008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200008.png');background-size:100% 100%; }
.icon-channel-200009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200009.png');background-size:100% 100%; }
.icon-channel-200010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200010.png');background-size:100% 100%; }
.icon-channel-200011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200011.png');background-size:100% 100%; }
.icon-channel-200012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200012.png');background-size:100% 100%; }
.icon-channel-200013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200013.png');background-size:100% 100%; }
.icon-channel-200014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200014.png');background-size:100% 100%; }
.icon-channel-200015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200015.png');background-size:100% 100%; }
.icon-channel-200016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200016.png');background-size:100% 100%; }
.icon-channel-200017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200017.png');background-size:100% 100%; }
.icon-channel-200018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200018.png');background-size:100% 100%; }
.icon-channel-200019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200019.png');background-size:100% 100%; }
.icon-channel-200020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200020.png');background-size:100% 100%; }
.icon-channel-200021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200021.png');background-size:100% 100%; }
.icon-channel-200022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200022.png');background-size:100% 100%; }
.icon-channel-200023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200023.png');background-size:100% 100%; }
.icon-channel-200024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200024.png');background-size:100% 100%; }
.icon-channel-200025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200025.png');background-size:100% 100%; }
.icon-channel-200026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200026.png');background-size:100% 100%; }
.icon-channel-200027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200027.png');background-size:100% 100%; }
.icon-channel-200028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200028.png');background-size:100% 100%; }
.icon-channel-200029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200029.png');background-size:100% 100%; }
.icon-channel-200030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200030.png');background-size:100% 100%; }
.icon-channel-200031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200031.png');background-size:100% 100%; }
.icon-channel-200032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200032.png');background-size:100% 100%; }
.icon-channel-200033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200033.png');background-size:100% 100%; }
.icon-channel-200034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200034.png');background-size:100% 100%; }
.icon-channel-200035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200035.png');background-size:100% 100%; }
.icon-channel-200036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200036.png');background-size:100% 100%; }
.icon-channel-200037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200037.png');background-size:100% 100%; }
.icon-channel-200038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200038.png');background-size:100% 100%; }
.icon-channel-200039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200039.png');background-size:100% 100%; }
.icon-channel-200040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200040.png');background-size:100% 100%; }
.icon-channel-200041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200041.png');background-size:100% 100%; }
.icon-channel-200042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200042.png');background-size:100% 100%; }
.icon-channel-200043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200043.png');background-size:100% 100%; }
.icon-channel-200044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200044.png');background-size:100% 100%; }
.icon-channel-200045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200045.png');background-size:100% 100%; }
.icon-channel-200046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200046.png');background-size:100% 100%; }
.icon-channel-200047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200047.png');background-size:100% 100%; }
.icon-channel-200048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/200048.png');background-size:100% 100%; }
.icon-channel-300001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300001.png');background-size:100% 100%; }
.icon-channel-300002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300002.png');background-size:100% 100%; }
.icon-channel-300003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300003.png');background-size:100% 100%; }
.icon-channel-300004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300004.png');background-size:100% 100%; }
.icon-channel-300005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300005.png');background-size:100% 100%; }
.icon-channel-300006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300006.png');background-size:100% 100%; }
.icon-channel-300007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300007.png');background-size:100% 100%; }
.icon-channel-300008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300008.png');background-size:100% 100%; }
.icon-channel-300009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300009.png');background-size:100% 100%; }
.icon-channel-300010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300010.png');background-size:100% 100%; }
.icon-channel-300011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300011.png');background-size:100% 100%; }
.icon-channel-300012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300012.png');background-size:100% 100%; }
.icon-channel-300013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300013.png');background-size:100% 100%; }
.icon-channel-300014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300014.png');background-size:100% 100%; }
.icon-channel-300015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300015.png');background-size:100% 100%; }
.icon-channel-300016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300016.png');background-size:100% 100%; }
.icon-channel-300017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300017.png');background-size:100% 100%; }
.icon-channel-300018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300018.png');background-size:100% 100%; }
.icon-channel-300019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300019.png');background-size:100% 100%; }
.icon-channel-300020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300020.png');background-size:100% 100%; }
.icon-channel-300021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300021.png');background-size:100% 100%; }
.icon-channel-300022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300022.png');background-size:100% 100%; }
.icon-channel-300023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300023.png');background-size:100% 100%; }
.icon-channel-300024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300024.png');background-size:100% 100%; }
.icon-channel-300025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300025.png');background-size:100% 100%; }
.icon-channel-300026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300026.png');background-size:100% 100%; }
.icon-channel-300027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300027.png');background-size:100% 100%; }
.icon-channel-300028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300028.png');background-size:100% 100%; }
.icon-channel-300029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300029.png');background-size:100% 100%; }
.icon-channel-300030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300030.png');background-size:100% 100%; }
.icon-channel-300031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300031.png');background-size:100% 100%; }
.icon-channel-300032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300032.png');background-size:100% 100%; }
.icon-channel-300033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300033.png');background-size:100% 100%; }
.icon-channel-300034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300034.png');background-size:100% 100%; }
.icon-channel-300035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300035.png');background-size:100% 100%; }
.icon-channel-300036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300036.png');background-size:100% 100%; }
.icon-channel-300037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300037.png');background-size:100% 100%; }
.icon-channel-300038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300038.png');background-size:100% 100%; }
.icon-channel-300039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300039.png');background-size:100% 100%; }
.icon-channel-300040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300040.png');background-size:100% 100%; }
.icon-channel-300041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300041.png');background-size:100% 100%; }
.icon-channel-300042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300042.png');background-size:100% 100%; }
.icon-channel-300043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300043.png');background-size:100% 100%; }
.icon-channel-300044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300044.png');background-size:100% 100%; }
.icon-channel-300045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300045.png');background-size:100% 100%; }
.icon-channel-300046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300046.png');background-size:100% 100%; }
.icon-channel-300047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300047.png');background-size:100% 100%; }
.icon-channel-300048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/300048.png');background-size:100% 100%; }
.icon-channel-400001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400001.png');background-size:100% 100%; }
.icon-channel-400002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400002.png');background-size:100% 100%; }
.icon-channel-400003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400003.png');background-size:100% 100%; }
.icon-channel-400004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400004.png');background-size:100% 100%; }
.icon-channel-400005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400005.png');background-size:100% 100%; }
.icon-channel-400006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400006.png');background-size:100% 100%; }
.icon-channel-400007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400007.png');background-size:100% 100%; }
.icon-channel-400008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400008.png');background-size:100% 100%; }
.icon-channel-400009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400009.png');background-size:100% 100%; }
.icon-channel-400010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400010.png');background-size:100% 100%; }
.icon-channel-400011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400011.png');background-size:100% 100%; }
.icon-channel-400012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400012.png');background-size:100% 100%; }
.icon-channel-400013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400013.png');background-size:100% 100%; }
.icon-channel-400014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400014.png');background-size:100% 100%; }
.icon-channel-400015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400015.png');background-size:100% 100%; }
.icon-channel-400016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400016.png');background-size:100% 100%; }
.icon-channel-400017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400017.png');background-size:100% 100%; }
.icon-channel-400018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400018.png');background-size:100% 100%; }
.icon-channel-400019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400019.png');background-size:100% 100%; }
.icon-channel-400020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400020.png');background-size:100% 100%; }
.icon-channel-400021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400021.png');background-size:100% 100%; }
.icon-channel-400022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400022.png');background-size:100% 100%; }
.icon-channel-400023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400023.png');background-size:100% 100%; }
.icon-channel-400024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400024.png');background-size:100% 100%; }
.icon-channel-400025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400025.png');background-size:100% 100%; }
.icon-channel-400026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400026.png');background-size:100% 100%; }
.icon-channel-400027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400027.png');background-size:100% 100%; }
.icon-channel-400028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400028.png');background-size:100% 100%; }
.icon-channel-400029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400029.png');background-size:100% 100%; }
.icon-channel-400030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400030.png');background-size:100% 100%; }
.icon-channel-400031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400031.png');background-size:100% 100%; }
.icon-channel-400032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400032.png');background-size:100% 100%; }
.icon-channel-400033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400033.png');background-size:100% 100%; }
.icon-channel-400034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400034.png');background-size:100% 100%; }
.icon-channel-400035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400035.png');background-size:100% 100%; }
.icon-channel-400036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400036.png');background-size:100% 100%; }
.icon-channel-400037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400037.png');background-size:100% 100%; }
.icon-channel-400038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400038.png');background-size:100% 100%; }
.icon-channel-400039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400039.png');background-size:100% 100%; }
.icon-channel-400040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400040.png');background-size:100% 100%; }
.icon-channel-400041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400041.png');background-size:100% 100%; }
.icon-channel-400042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400042.png');background-size:100% 100%; }
.icon-channel-400043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400043.png');background-size:100% 100%; }
.icon-channel-400044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400044.png');background-size:100% 100%; }
.icon-channel-400045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400045.png');background-size:100% 100%; }
.icon-channel-400046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400046.png');background-size:100% 100%; }
.icon-channel-400047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400047.png');background-size:100% 100%; }
.icon-channel-400048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/400048.png');background-size:100% 100%; }
.icon-channel-500001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500001.png');background-size:100% 100%; }
.icon-channel-500002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500002.png');background-size:100% 100%; }
.icon-channel-500003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500003.png');background-size:100% 100%; }
.icon-channel-500004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500004.png');background-size:100% 100%; }
.icon-channel-500005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500005.png');background-size:100% 100%; }
.icon-channel-500006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500006.png');background-size:100% 100%; }
.icon-channel-500007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500007.png');background-size:100% 100%; }
.icon-channel-500008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500008.png');background-size:100% 100%; }
.icon-channel-500009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500009.png');background-size:100% 100%; }
.icon-channel-500010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500010.png');background-size:100% 100%; }
.icon-channel-500011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500011.png');background-size:100% 100%; }
.icon-channel-500012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500012.png');background-size:100% 100%; }
.icon-channel-500013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500013.png');background-size:100% 100%; }
.icon-channel-500014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500014.png');background-size:100% 100%; }
.icon-channel-500015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500015.png');background-size:100% 100%; }
.icon-channel-500016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500016.png');background-size:100% 100%; }
.icon-channel-500017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500017.png');background-size:100% 100%; }
.icon-channel-500018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500018.png');background-size:100% 100%; }
.icon-channel-500019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500019.png');background-size:100% 100%; }
.icon-channel-500020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500020.png');background-size:100% 100%; }
.icon-channel-500021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500021.png');background-size:100% 100%; }
.icon-channel-500022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500022.png');background-size:100% 100%; }
.icon-channel-500023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500023.png');background-size:100% 100%; }
.icon-channel-500024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500024.png');background-size:100% 100%; }
.icon-channel-500025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500025.png');background-size:100% 100%; }
.icon-channel-500026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500026.png');background-size:100% 100%; }
.icon-channel-500027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500027.png');background-size:100% 100%; }
.icon-channel-500028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500028.png');background-size:100% 100%; }
.icon-channel-500029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500029.png');background-size:100% 100%; }
.icon-channel-500030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500030.png');background-size:100% 100%; }
.icon-channel-500031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500031.png');background-size:100% 100%; }
.icon-channel-500032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500032.png');background-size:100% 100%; }
.icon-channel-500033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500033.png');background-size:100% 100%; }
.icon-channel-500034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500034.png');background-size:100% 100%; }
.icon-channel-500035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500035.png');background-size:100% 100%; }
.icon-channel-500036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500036.png');background-size:100% 100%; }
.icon-channel-500037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500037.png');background-size:100% 100%; }
.icon-channel-500038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500038.png');background-size:100% 100%; }
.icon-channel-500039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500039.png');background-size:100% 100%; }
.icon-channel-500040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500040.png');background-size:100% 100%; }
.icon-channel-500041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500041.png');background-size:100% 100%; }
.icon-channel-500042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500042.png');background-size:100% 100%; }
.icon-channel-500043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500043.png');background-size:100% 100%; }
.icon-channel-500044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500044.png');background-size:100% 100%; }
.icon-channel-500045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500045.png');background-size:100% 100%; }
.icon-channel-500046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500046.png');background-size:100% 100%; }
.icon-channel-500047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500047.png');background-size:100% 100%; }
.icon-channel-500048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/500048.png');background-size:100% 100%; }
.icon-channel-600001{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600001.png');background-size:100% 100%; }
.icon-channel-600002{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600002.png');background-size:100% 100%; }
.icon-channel-600003{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600003.png');background-size:100% 100%; }
.icon-channel-600004{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600004.png');background-size:100% 100%; }
.icon-channel-600005{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600005.png');background-size:100% 100%; }
.icon-channel-600006{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600006.png');background-size:100% 100%; }
.icon-channel-600007{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600007.png');background-size:100% 100%; }
.icon-channel-600008{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600008.png');background-size:100% 100%; }
.icon-channel-600009{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600009.png');background-size:100% 100%; }
.icon-channel-600010{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600010.png');background-size:100% 100%; }
.icon-channel-600011{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600011.png');background-size:100% 100%; }
.icon-channel-600012{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600012.png');background-size:100% 100%; }
.icon-channel-600013{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600013.png');background-size:100% 100%; }
.icon-channel-600014{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600014.png');background-size:100% 100%; }
.icon-channel-600015{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600015.png');background-size:100% 100%; }
.icon-channel-600016{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600016.png');background-size:100% 100%; }
.icon-channel-600017{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600017.png');background-size:100% 100%; }
.icon-channel-600018{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600018.png');background-size:100% 100%; }
.icon-channel-600019{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600019.png');background-size:100% 100%; }
.icon-channel-600020{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600020.png');background-size:100% 100%; }
.icon-channel-600021{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600021.png');background-size:100% 100%; }
.icon-channel-600022{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600022.png');background-size:100% 100%; }
.icon-channel-600023{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600023.png');background-size:100% 100%; }
.icon-channel-600024{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600024.png');background-size:100% 100%; }
.icon-channel-600025{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600025.png');background-size:100% 100%; }
.icon-channel-600026{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600026.png');background-size:100% 100%; }
.icon-channel-600027{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600027.png');background-size:100% 100%; }
.icon-channel-600028{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600028.png');background-size:100% 100%; }
.icon-channel-600029{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600029.png');background-size:100% 100%; }
.icon-channel-600030{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600030.png');background-size:100% 100%; }
.icon-channel-600031{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600031.png');background-size:100% 100%; }
.icon-channel-600032{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600032.png');background-size:100% 100%; }
.icon-channel-600033{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600033.png');background-size:100% 100%; }
.icon-channel-600034{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600034.png');background-size:100% 100%; }
.icon-channel-600035{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600035.png');background-size:100% 100%; }
.icon-channel-600036{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600036.png');background-size:100% 100%; }
.icon-channel-600037{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600037.png');background-size:100% 100%; }
.icon-channel-600038{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600038.png');background-size:100% 100%; }
.icon-channel-600039{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600039.png');background-size:100% 100%; }
.icon-channel-600040{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600040.png');background-size:100% 100%; }
.icon-channel-600041{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600041.png');background-size:100% 100%; }
.icon-channel-600042{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600042.png');background-size:100% 100%; }
.icon-channel-600043{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600043.png');background-size:100% 100%; }
.icon-channel-600044{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600044.png');background-size:100% 100%; }
.icon-channel-600045{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600045.png');background-size:100% 100%; }
.icon-channel-600046{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600046.png');background-size:100% 100%; }
.icon-channel-600047{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600047.png');background-size:100% 100%; }
.icon-channel-600048{ background:url('https://ying-prod.oss-cn-beijing.aliyuncs.com/icons/channel/600048.png');background-size:100% 100%; }

@white-color: #FFFFFF;