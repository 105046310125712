.footer {
  background: @white-color;
  box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.18);
  height: 90px;
  position: absolute;
  left: 0;
  bottom: 0;
  .footer-wrap {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    .cy-1 {
      font-size: 14px;
      color: #020202;
    }
    .cy-2 {
      font-size: 14px;
      color: #020202;
    }
    .cy-3 {
      font-size: 14px;
      color: #020202;
    }
    .gov-logo {
      margin-right: 20px;
    }
    .footer-content {
      .footer-content-left {
        box-sizing: border-box;
        width: 200px;
        height: 90px;
        background-color: #910a14;
        padding: 15px 30px;
        margin-right: 45px;
        border-radius: 3px;
        flex: 0 0 auto;
        .tips-con {
          a {
            color: @white-color;
            font-size: 12px;
            margin-bottom: 2px;
          }
        }
      }
      .footer-content-right {
        box-sizing: border-box;
        width: 230px;
        height: 90px;
        background-color: #910a14;
        padding: 15px 20px;
        margin-left: 45px;
        border-radius: 3px;
        flex: 0 0 auto;
        .tips-con {
          span {
            color: @white-color;
            font-size: 12px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}

@white-color: #FFFFFF;