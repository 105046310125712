.main-body {
  > .main-content {
    padding-top: 50px;
    margin-left: 260px;
    min-height: calc(100vh - 118px);
  }
  > .sidebar-con {
    background: var(--ying-menu-bg-color);
    box-shadow: 4px 0px 8px 0px rgba(58, 44, 28, 0.11);
    position: fixed;
    left: 0;
    top: 50px;
    height: calc(100% - 50px);
  }
}

@white-color: #FFFFFF;