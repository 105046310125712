.sidebar-container {
  background: var(--ying-menu-bg-color);
  .ant-menu {
    background: var(--ying-menu-bg-color);
    color: var(--ying-menu-txt-color);
  }
  .ant-menu-inline {
    border-right: 1px solid var(--ying-menu-bg-color);
  }
  .ant-menu-sub.ant-menu-inline {
    background: var(--ying-menu-bg-color);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-inline .ant-menu-item-selected {
    background: var(--ying-menu-bg-color);
    padding: 0 24px !important;
    > .ant-menu-title-content {
      background: var(--ying-primary-color);
      border-radius: 3px;
      color: @white-color;
      padding-left: 24px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu {
    &.ant-menu-submenu-selected {
      color: var(--ying-menu-main-color);
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
    &.ant-menu-item-selected {
      color: var(--ying-menu-main-color);
    }
  }
  .ant-menu:not(.ant-menu-horizontal) > .ant-menu-item-selected {
    background: var(--ying-menu-bg-color);
    &::after {
      display: none;
    }
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    &::after {
      display: none;
    }
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background: var(--ying-menu-bg-color);
  }
}

@white-color: #FFFFFF;