.main-header {
  height: 50px;
  background: var(--ying-page-header-color);
  .brand {
    height: 100%;
    padding-left: 20px;
    .brand-img {
      width: 34px;
      height: 34px;
    }
    .brand-info {
      margin-left: 10px;
      display: flex;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .tip-1 {
        font-size: 12px;
        color: @white-color;
      }
      .tip-2 {
        font-size: 12px;
        color: @white-color;
      }
      .devide-line {
        width: 1px;
        height: 12px;
        background: var(--ying-border-color);
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .user-container {
    padding-right: 16px;
    color: @white-color;
    .user-btn {
      cursor: pointer;
      span {
        margin-left: 5px;
      }
    }
  }
}

// 修改密码
.edit-code-con {
  .edit-code {
    cursor: pointer;
    color: var(--ying-menu-txt-color);
  }
}

@white-color: #FFFFFF;